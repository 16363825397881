<template>
  <v-autocomplete
    v-model="selectOption"
    :items="componentTypes"
    :label="label"
    :loading="loading"
    :search-input.sync="search"
    class="filter-select text-sm"
    hide-details
    hide-no-data
    item-text="name"
    item-value="id"
    no-filter
  ></v-autocomplete>
</template>

<script>
export default {
  name: 'ClientTypeSelector',
  props: {
    value: {},
    label: {
      default: 'Tipul clientului'
    }
  },
  data () {
    return {
      paginatedData: {
        data: []
      },
      loading: false,
      search: ''
    }
  },
  computed: {
    componentTypes () {
      return this.paginatedData.data
    },
    selectOption: {
      get () {
        return this.value
      },
      set (val) {
        if (typeof val === 'undefined') {
          this.$emit('input', '')
        } else {
          this.$emit('input', val)
        }
      }
    }
  },
  watch: {
    search (val) {
      this.loading = true
      this.loadItems(val)
    }
  },
  methods: {
    loadItems (search) {
      search = search || ''
      this.$http.get(`/clients/type?filter[name]=${search}&filter[include-resellers]=no`)
        .then(({data}) => {
          const aux = data
          for (const [key, value] of Object.entries(aux)) {
            this.paginatedData.data.push({
              name: key,
              id: value
            })
          }
          // this.paginatedData = data
          this.loading = false
        })

    }
  },
  created () {
    this.loadItems()
  }
}
</script>

<style lang="scss" scoped>
.vs-con-input-label {
  width: auto;
}
</style>
<style lang="scss">
.con-img-upload {
  padding: 0;

  .con-input-upload {
    width: 100%;
    height: 48px;
  }
}
</style>
