<template>
  <v-sheet>
    <v-row>
      <v-col cols="4">
        <reseller-confirmation-buttons v-if="client.is_reseller" :reseller="client"/>
        <end-user-confirmation-buttons v-else :client="client"/>
      </v-col>
    </v-row>
    <v-row class="mb-4">
      <v-col cols="6">
        <customer-personal-information ref="personalInfo"/>
      </v-col>
      <v-col v-if="client.is_company" cols="6">
        <customer-company-information ref="companyInfo"/>
      </v-col>
    </v-row>
    <v-divider/>
    <v-row class="mt-4">
      <v-col>
        <v-btn :disabled="loading" :loading="loading" color="success" class="primary-button" @click="save">Salveaza</v-btn>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import CustomerPersonalInformation from '@/views/SingleClient/components/CustomerPersonalInformation'
import CustomerCompanyInformation from '@/views/SingleClient/components/CustomerCompanyInformation'
import ResellerConfirmationButtons from '@/views/SingleClient/components/ResellerConfirmationButtons'
import EndUserConfirmationButtons from '@/views/SingleClient/components/EndUserConfirmationButtons'

export default {
  components: {
    EndUserConfirmationButtons,
    ResellerConfirmationButtons,
    CustomerCompanyInformation,
    CustomerPersonalInformation
  },
  props: {
    client: {
      required: true
    }
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    async save () {

      this.loading = true

      try {

        await this.$refs.personalInfo.save()

        if (this.client.is_company) {
          await this.$refs.companyInfo.save()
        }

        await this.$store.dispatch('singleClient/load', this.client.id)

        this.loading = false

        this.$vs.notify({
          title: 'Succes!',
          text: 'Clientul a fost salvat cu succes.',
          color: 'success'
        })

      } catch (e) {
        this.$vs.notify({
          title: 'Eroare',
          text: 'Eroare la salvare, va rugam reimprospatati pagina sau incercati mai tarziu',
          color: 'danger'
        })
        this.loading = false
      }

    }
  }
}
</script>
