var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.paginatedData && _vm.paginatedData.data)?_c('section',{staticClass:"data-list-container"},[_c('div',{staticClass:"mb-5"}),_c('vx-card',[_c('v-data-table',{attrs:{"headers":_vm.headers,"hide-default-footer":true,"items":_vm.paginatedData.data},on:{"update:sort-by":_vm.updateSortField,"update:sort-desc":_vm.updateSortType},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return [_c('tr',_vm._l((props.headers),function(header){return _c('th',{key:header.text},[(header.value === 'order_number')?[_c('filter-input',{attrs:{"filter-name":header.value,"placeholder":"Filter..."},on:{"filter":_vm.updateFilter}})]:_vm._e(),(header.value === 'date_verbose')?[_c('date-type-filter',{attrs:{"filter-name":header.value},on:{"filter":_vm.updateFilter}})]:_vm._e(),(header.value === 'status_name')?[_c('order-status-filter',{attrs:{"filter-name":"status","placeholder":"Filter..."},on:{"filter":_vm.updateFilter}})]:_vm._e(),(header.value === 'customer.agent.full_name')?[_c('agent-type-filter',{attrs:{"filter-name":header.value},on:{"filter":_vm.updateFilter}})]:_vm._e()],2)}),0)]}},{key:"item.status_name",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.status_details.color}},[_vm._v(_vm._s(item.status_details.name))])]}},{key:"item.date_verbose",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.confirmed_at,'D-MM-YYYY H:mm:ss'))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('view-button',{attrs:{"to":{ name: 'orders-page', params: { id: item.id } }}})]}},{key:"footer",fn:function(){return [_c('v-pagination',{attrs:{"length":_vm.paginatedData.last_page,"total-visible":5,"value":_vm.currentPage,"color":"#76bd43"},on:{"input":_vm.changePage}})]},proxy:true}],null,false,473425931)})],1)],1):_c('section',{staticClass:"data-list-container text-center"},[_c('v-card-title',{staticClass:"font-weight-bold justify-center"},[_vm._v(" No data available ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }