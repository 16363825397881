<template>
  <section class="data-list-container">
    <vx-card>
      <v-data-table
        :headers="headers"
        :hide-default-footer="true"
        :items="paginatedItems.data"
        @update:sort-by="updateSortField"
        @update:sort-desc="updateSortType"
      >

        <template v-slot:header="{props}">
          <tr>
            <th v-for="header in props.headers" :key="header.text">

              <template v-if="header.value === 'company'">
                <filter-input :filter-name="header.value" placeholder="Filter..." @filter="updateFilter"/>
              </template>

              <template v-if="header.value === 'agent'">
                <agent-filter :filter-name="header.value" placeholder="Filtreaza" @filter="updateFilter"/>
              </template>
              <template v-if="header.value === 'customer'">
                <customer-filter :filter-name="header.value" placeholder="Filtreaza" @filter="updateFilter"/>
              </template>

              <template v-if="header.value === 'created_at'">
                <date-filter :filter-name="header.value" @filter="updateFilter"/>
              </template>

            </th>
          </tr>
        </template>


        <template v-slot:item.customer="{item}">
          {{ item.customer.client_display_name }}
        </template>

        <template v-slot:item.company="{item}">
          <template v-if="item.customer.company">{{ item.customer.company.name }}</template>
          <template v-else>-</template>
        </template>

        <template v-slot:item.time="{item}">
          {{ item.time | toSeconds }}
        </template>

        <template v-slot:item.referrer_page="{item}">
          <a :href="item.full_referrer_page" target="_blank">{{ item.referrer_page }}</a>
        </template>

        <template v-slot:item.accessed_page="{item}">
          <a :href="item.full_accessed_page" target="_blank">{{ item.accessed_page }}</a>
        </template>

        <template v-slot:item.agent="{item}">
          <client-agent :agent="item.customer.agent"/>
        </template>

        <template v-slot:item.created_at="{item}">
          {{ item.created_at | moment('HH:mm in DD.MM.YYYY') }}
        </template>


        <template v-slot:footer>
          <v-pagination
            :length="paginatedItems.last_page"
            :total-visible="5"
            :value="currentPage"
            color="#76bd43"
            @input="changePage"
          ></v-pagination>
        </template>

      </v-data-table>
    </vx-card>


  </section>
</template>

<script>
import NewButton from '@/components/general-form/NewButton'
import EditButton from '@/components/general-form/EditButton'
import DeleteButton from '@/components/general-form/DeleteButton'
import FilterInput from '@/components/general-form/FilterInput'
import ClientAgent from '@/views/Clients/components/ClientAgent'
import AgentFilter from '@/views/Activity/components/Filters/AgentFilter'
import CustomerFilter from '@/views/Activity/components/Filters/CustomerFilter'
import DateFilter from '@/views/Activity/components/Filters/DateFilter'

export default {
  name: 'CustomerActivityList',
  components: {
    DateFilter,
    CustomerFilter,
    AgentFilter,
    ClientAgent,
    FilterInput,
    DeleteButton,
    EditButton,
    NewButton
  },
  props: {
    customerId: {
      default: false
    }
  },
  data () {
    return {
      headers: [
        {
          text: 'ID',
          value: 'id',
          width: '65'
        },
        {
          text: 'Sursa',
          value: 'referrer_page',
          sortable: false
        },
        {
          text: 'Url',
          value: 'accessed_page',
          sortable: false
        },
        {
          text: 'Durata',
          value: 'time',
          sortable: true
        },
        {
          text: 'Data',
          value: 'created_at',
          sortable: true
        }

      ],
      sortFiled: [],
      sortIsDesc: []
    }
  },
  filters: {
    toSeconds (value) {
      value = parseInt(value)
      return `${Math.floor(value / 1000)}s`
    }
  },
  computed: {
    paginatedItems () {
      return this.$store.getters['activity/paginatedData']
    },
    currentPage () {
      return this.$store.getters['activity/currentPage']
    },
    sortParam () {
      if (this.sortFiled.length) {
        const sortString = []
        for (let i = 0; i < this.sortFiled.length; i++) {
          sortString.push((this.sortIsDesc[i] === true ? '-' : '') + this.sortFiled[i])
        }
        return sortString.join(',')
      }
      return ''
    }
  },
  methods: {
    changePage (newPage) {
      this.$store.dispatch('activity/changePage', newPage)
    },
    updateSortField (field) {
      this.sortFiled = field
    },
    updateSortType (isDesc) {
      this.sortIsDesc = isDesc
    },
    updateFilter (searchValue, filterName) {
      if (searchValue === '') {
        this.$store.dispatch('activity/deleteFilter', filterName)
      } else {
        this.$store.dispatch('activity/addFilter', {
          filterName,
          searchValue
        })
      }
    },
    loadItems () {
      this.$store.dispatch('activity/addFilter', {
        filterName: 'customer',
        searchValue: this.customerId
      })
      this.$store.dispatch('activity/loadItems')
    }
  },
  watch: {
    sortParam (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$store.dispatch('activity/changeSort', newValue)
      }
    }
  },
  created () {
    this.loadItems()
  }
}
</script>
