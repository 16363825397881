var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"data-list-container"},[_c('vx-card',[_c('v-data-table',{attrs:{"headers":_vm.headers,"hide-default-footer":true,"items":_vm.paginatedItems.data},on:{"update:sort-by":_vm.updateSortField,"update:sort-desc":_vm.updateSortType},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return [_c('tr',_vm._l((props.headers),function(header){return _c('th',{key:header.text},[(header.value === 'company')?[_c('filter-input',{attrs:{"filter-name":header.value,"placeholder":"Filter..."},on:{"filter":_vm.updateFilter}})]:_vm._e(),(header.value === 'agent')?[_c('agent-filter',{attrs:{"filter-name":header.value,"placeholder":"Filtreaza"},on:{"filter":_vm.updateFilter}})]:_vm._e(),(header.value === 'customer')?[_c('customer-filter',{attrs:{"filter-name":header.value,"placeholder":"Filtreaza"},on:{"filter":_vm.updateFilter}})]:_vm._e(),(header.value === 'created_at')?[_c('date-filter',{attrs:{"filter-name":header.value},on:{"filter":_vm.updateFilter}})]:_vm._e()],2)}),0)]}},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.customer.client_display_name)+" ")]}},{key:"item.company",fn:function(ref){
var item = ref.item;
return [(item.customer.company)?[_vm._v(_vm._s(item.customer.company.name))]:[_vm._v("-")]]}},{key:"item.time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toSeconds")(item.time))+" ")]}},{key:"item.referrer_page",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.full_referrer_page,"target":"_blank"}},[_vm._v(_vm._s(item.referrer_page))])]}},{key:"item.accessed_page",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.full_accessed_page,"target":"_blank"}},[_vm._v(_vm._s(item.accessed_page))])]}},{key:"item.agent",fn:function(ref){
var item = ref.item;
return [_c('client-agent',{attrs:{"agent":item.customer.agent}})]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at,'HH:mm in DD.MM.YYYY'))+" ")]}},{key:"footer",fn:function(){return [_c('v-pagination',{attrs:{"length":_vm.paginatedItems.last_page,"total-visible":5,"value":_vm.currentPage,"color":"#76bd43"},on:{"input":_vm.changePage}})]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }