<template>
  <v-card>
    <v-card-title>
      <template v-if="client.is_company">{{ client.company.name }},</template>
      {{ client.full_name }}
      <span class="font-weight-bold mx-1">Status:</span>
      <reseller-status v-if="client.is_reseller" :client="client"/>
      <end-user-status v-else :client="client"/>
    </v-card-title>
    <v-card-text>
      <v-tabs v-model="tab">
        <v-tab>
          Detalii Cont
        </v-tab>
        <v-tab>
          Comenzi
        </v-tab>
        <v-tab>
          Cos de cumparaturi
        </v-tab>
        <v-tab>
          Activitate
        </v-tab>
        <v-tab>
          Istoric contact
        </v-tab>
        <v-tab>
          Cautari
        </v-tab>
        <v-tab>
          Istoric activitati
        </v-tab>
      </v-tabs>
      <div class="p-5">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <account-details-editor :client="client"/>
          </v-tab-item>
          <v-tab-item>
            <customer-order-list :customer-id="currentClientID"/>
          </v-tab-item>
          <v-tab-item>
            <single-client-cart-display :customer-id="currentClientID"/>
          </v-tab-item>
          <v-tab-item>
            <customer-activity-list :customer-id="currentClientID"/>
          </v-tab-item>
          <v-tab-item>
            <contact-history-list :customer-id="currentClientID"></contact-history-list>
          </v-tab-item>
          <v-tab-item>
            <search-log-display :customer-id="currentClientID"/>
          </v-tab-item>
          <v-tab-item>
            <activity-history :customer-id="currentClientID"></activity-history>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import CustomerOrderList from '@/views/SingleClient/components/CustomerOrderList'
import ResellerStatus from '@/views/SingleClient/components/ResellerStatus'
import EndUserStatus from '@/views/SingleClient/components/EndUserStatus'
import SingleClientCartDisplay from '@/views/SingleClient/components/SingleClientCartDisplay'
import CustomerActivityList from '@/views/SingleClient/components/CustomerActivityList'
import ContactHistoryList from '@/views/SingleClient/components/ContactHistoryList'
import ActivityHistory from '@/views/SingleClient/components/ActivityHistory'
import SearchLogDisplay from '@/components/search/SearchLogDisplay'
import AccountDetailsEditor from '@/views/SingleClient/components/AccountDetailsEditor'

export default {
  name: 'View',
  components: {
    AccountDetailsEditor,
    SearchLogDisplay,
    ContactHistoryList,
    CustomerActivityList,
    SingleClientCartDisplay,
    EndUserStatus,
    ResellerStatus,
    CustomerOrderList,
    ActivityHistory
  },
  data () {
    return {
      tab: ''
    }
  },
  computed: {
    currentClientID () {
      return this.$route.params.id
    },
    client () {
      return this.$store.getters['singleClient/item']
    }
  },
  methods: {
    loadClient () {
      this.$store.dispatch('singleClient/load', this.currentClientID)
    }
  },
  created () {
    this.loadClient()
  }
}
</script>
<style lang="scss" scoped>
</style>
