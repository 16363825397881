<template>
  <v-card elevation="0">
    <div v-if="cart.updated_at">
      <v-card-title>Ultimul update la {{ cart.updated_at | moment("HH:mm in DD.MM.YYYY") }}</v-card-title>
    </div>

    <v-card-text>
      <v-data-table
        :disable-pagination="true"
        :headers="productTableHeaders"
        :hide-default-footer="true"
        :items="cart.items"
      >

        <template v-slot:item.featured_image="{item}">
          <img :src="item.item.featured_image.src" alt="" width="50">
        </template>

        <template v-slot:footer>
          <v-row>
            <v-col>
              <table class="ml-auto text-right total-table">
                <tr>
                  <td>Total Produse:</td>
                  <td><strong>{{ cart.item_total }}</strong>Lei</td>
                </tr>
                <tr>
                  <td>TVA:</td>
                  <td><strong>{{ cart.total_vat }}</strong>Lei</td>
                </tr>
                <tr>
                  <td>Taxa verde:</td>
                  <td><strong>{{ cart.total_green_tax }}</strong>Lei</td>
                </tr>
                <tr>
                  <td>Total Cos:</td>
                  <td><strong>{{ cart.total }}</strong>Lei</td>
                </tr>
              </table>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'SingleClientCartDisplay',
  props: {
    customerId: {
      required: true
    }
  },
  data() {
    return {
      cart: {},
      productTableHeaders: [
        {align: 'start', text: 'Imagine', value: 'featured_image', sortable: false},
        {align: 'start', text: 'Produs', value: 'item.full_name', sortable: false},
        {align: 'start', text: 'Pret', value: 'total_item', sortable: false},
        {align: 'start', text: 'Taxa verde', value: 'total_green_tax', sortable: false},
        {align: 'start', text: 'Cantitate', value: 'quantity', sortable: false},
        {align: 'start', text: '', value: 'actions', sortable: false}
      ]
    }
  },
  mounted() {
    this.$http.get(`clients/${this.customerId}/cart`)
      .then(({data}) => {
        this.cart = data
      })
  }
}
</script>

