<template>
  <v-card flat>
    <v-btn v-if="!client.activated" color="primary" class="primary-button" small @click="resendConfirmationEmail">
      Trimite mail de confirmare
    </v-btn>
  </v-card>
</template>

<script>
export default {
  name: 'EndUserConfirmationButtons',
  props:{
    client:{
      required:true
    }
  },
  methods:{
    resendConfirmationEmail () {
      this.$http.patch(`clients/${this.client.id}/send-email-notification`)
        .then(() => {
          this.$vs.notify({
            title: 'Succes!',
            text: 'Emailul de confirmare a fost trimis cu succes!',
            color: 'success'
          })
        })
        .catch(() => {
          this.$vs.notify({
            title: 'Eroare!',
            text: 'A aparut o eroare la trimiterea emailului. Va rugam reincarcati pagina sau incercati mai tarziu',
            color: 'danger'
          })
        })

    }
  }
}
</script>

<style scoped>

</style>