<template>
  <section class="data-list-container" v-if="paginatedData && paginatedData.data">

    <div class="mb-5">
    </div>

    <vx-card>
      <v-data-table
      :headers="headers"
      :hide-default-footer="true"
      :items="paginatedData.data"
      @update:sort-by="updateSortField"
      @update:sort-desc="updateSortType"
      >

        <template v-slot:header="{ props }">
          <tr>
            <th v-for="header in props.headers" :key="header.text">


              <template v-if="header.value === 'order_number'">
                <filter-input :filter-name="header.value" placeholder="Filter..." @filter="updateFilter" />
              </template>

              <template v-if="header.value === 'date_verbose'">
                <date-type-filter :filter-name="header.value" @filter="updateFilter" />
              </template>

              <template v-if="header.value === 'status_name'">
                <order-status-filter filter-name="status" placeholder="Filter..." @filter="updateFilter" />
              </template>

              <template v-if="header.value === 'customer.agent.full_name'">
                <agent-type-filter :filter-name="header.value" @filter="updateFilter" />
              </template>
            </th>
          </tr>
        </template>

        <template v-slot:item.status_name="{ item }">
          <v-chip :color="item.status_details.color">{{ item.status_details.name }}</v-chip>
        </template>

        <template v-slot:item.date_verbose="{ item }">
          {{ item.confirmed_at | moment('D-MM-YYYY H:mm:ss') }}
        </template>

        <template v-slot:item.actions="{ item }">
          <view-button :to="{ name: 'orders-page', params: { id: item.id } }" />
        </template>


        <template v-slot:footer>
          <v-pagination :length="paginatedData.last_page" :total-visible="5" :value="currentPage" color="#76bd43"
            @input="changePage"></v-pagination>
        </template>

      </v-data-table>
    </vx-card>


  </section>
  <section class="data-list-container text-center" v-else>
    <v-card-title class="font-weight-bold justify-center">
      No data available

    </v-card-title>
  </section>
</template>

<script>
import NewButton from '@/components/general-form/NewButton'
import EditButton from '@/components/general-form/EditButton'
import BooleanToText from '@/components/BooleanToText'
import DeleteButton from '@/components/general-form/DeleteButton'
import ViewButton from '@/components/general-form/ViewButton'
import DateTypeFilter from '@/views/Clients/components/Filters/DateTypeFilter'
import FilterInput from '@/components/general-form/FilterInput'
import AgentTypeFilter from '@/views/Orders/components/Filters/AgentTypeFilter'
import OrderStatusFilter from '@/views/Orders/components/Filters/OrderStatusFilter'

export default {
  name: 'CustomerOrderList',
  components: {
    // OrderStatusFilter,
    // AgentTypeFilter,
    // FilterInput,
    // DateTypeFilter,
    ViewButton,
    BooleanToText,
    DeleteButton,
    EditButton,
    NewButton
  },
  props: {
    customerId: {
      required: true
    }
  },
  data() {
    return {
      paginatedData : {},
      headers: [
        {
          align: 'start',
          text: 'Nr',
          value: 'order_number',
          sortable: false,
          width: '200'
        },
        {
          align: 'start',
          text: 'Data comenzii',
          value: 'date_verbose',
          sortable: true,
          width: '200'
        },
        {
          align: 'center',
          text: 'Valoare',
          value: 'total',
          sortable: false,
          width: '100'
        },
        {
          align: 'center',
          text: 'Status',
          value: 'status_name',
          sortable: false,
          width: '200'
        },
        {
          align: 'center',
          text: 'Agent',
          value: 'customer.agent.full_name',
          sortable: false,
          width: '200'
        },

        {
          align: 'right',
          text: '',
          value: 'actions',
          width: '70',
          sortable: false
        }
      ],
      sortFiled: [],
      sortIsDesc: []
    }
  },
  computed: {
    paginatedItems() {
      return this.$store.getters['orders/paginatedData']
    },
    currentPage() {
      return this.$store.getters['orders/currentPage']
    },
    sortParam() {
      if (this.sortFiled.length) {
        const sortString = []
        for (let i = 0; i < this.sortFiled.length; i++) {
          sortString.push((this.sortIsDesc[i] === true ? '-' : '') + this.sortFiled[i])
        }
        return sortString.join(',')
      }
      return ''
    }
  },
  methods: {
    changePage(newPage) {
      this.$store.dispatch('orders/changePage', newPage)
    },
    deleteItem(item) {
      this.$store.dispatch('orders/deleteItem', item.id)
    },
    updateSortField(field) {
      this.sortFiled = field
    },
    updateSortType(isDesc) {
      this.sortIsDesc = isDesc
    },
    updateFilter(searchValue, filterName) {
      if (searchValue === '') {
        this.$store.dispatch('orders/deleteFilter', filterName)
      } else {
        this.$store.dispatch('orders/addFilter', {
          filterName,
          searchValue
        })
      }
    },
    loadItems() {
      this.$store.dispatch('orders/addFilter', {
        filterName: 'customer',
        searchValue: this.customerId
      })
      this.$store.dispatch('orders/loadItems')
    },
    resendConfirmationEmail(item) {
      this.$http.patch(`orders/${item.id}/send-email-notification`)
        .then(() => {
          this.$vs.notify({
            title: 'Succes!',
            text: 'Emailul de confirmare a fost trimis cu succes!',
            color: 'success'
          })
        })
        .catch(() => {
          this.$vs.notify({
            title: 'Eroare!',
            text: 'A aparut o eroare la trimiterea emailului. Va rugam reincarcati pagina sau incercati mai tarziu',
            color: 'danger'
          })
        })

    }
  },
  watch: {
    sortParam(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$store.dispatch('orders/changeSort', newValue)
      }
    }
  },
  mounted() {
    if (!this.$store.getters['agents/isLoaded']) {
      this.$store.dispatch('agents/loadItems')

      this.$http.get(`clients/${this.customerId}/orders`)
      .then(({data}) => {
        debugger;
        this.paginatedData = data
      })
    }
  }
}
</script>
