<template>
  <v-card class="p-2"  flat>
    <template slot="progress">
      <v-progress-linear
        absolute
        bottom
        color="#01a7e3"
        height="5"
        indeterminate
        rounded
      ></v-progress-linear>
    </template>
    <div class="mb-4">
      <v-text-field v-model="localCompany.name" dense hide-details label="Nume" outlined/>
      <error-messages :errors="errors.name"/>
    </div>

    <div class="mb-4">
      <v-text-field v-model="localCompany.vat" dense hide-details label="CUI" outlined/>
      <error-messages :errors="errors.vat"/>
    </div>

    <div class="mb-4">
      <v-text-field v-model="localCompany.reg_number" dense hide-details label="Nr.Reg.Com" outlined/>
      <error-messages :errors="errors.reg_number"/>
    </div>

    <div class="mb-4">
      <v-text-field v-model="localCompany.contact_person" dense hide-details label="Persoana de contact" outlined/>
      <error-messages :errors="errors.contact_person"/>
    </div>

    <div class="mb-4">
      <v-text-field v-model="localCompany.phone_mobile" dense hide-details label="Nr. de telefon" outlined/>
      <error-messages :errors="errors.phone_mobile"/>
    </div>

    <div class="mb-4">
      <v-text-field v-model="localCompany.phone" dense hide-details label="Nr. de telefon (fix)" outlined/>
      <error-messages :errors="errors.phone"/>
    </div>

    <div class="mb-4">
      <v-text-field v-model="localCompany.activity" dense hide-details label="Activitate" outlined/>
      <error-messages :errors="errors.activity"/>
    </div>

    <div class="mb-4">
      <v-text-field v-model="localCompany.caen" dense hide-details label="Cod Caen" outlined/>
      <error-messages :errors="errors.caen"/>
    </div>

    <div class="mb-4">
      <v-text-field v-model="localCompany.caen_explained" dense hide-details label="Explicatie Cod caen" outlined/>
      <error-messages :errors="errors.caen_explained"/>
    </div>

    <div class="mb-4">
      <v-text-field v-model="localCompany.net_worth" dense hide-details label="Cifra de afacere" outlined/>
      <error-messages :errors="errors.net_worth"/>
    </div>

    <div class="mb-4">
      <v-text-field v-model="localCompany.number_of_employees" dense hide-details label="Numar angajati" outlined/>
      <error-messages :errors="errors.number_of_employees"/>
    </div>

    <div class="mb-4">
      <v-text-field v-model="localCompany.website" dense hide-details label="Website" outlined/>
      <error-messages :errors="errors.website"/>
    </div>
  </v-card>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'
import _ from 'lodash'

export default {
  name: 'CustomerCompanyInformation',
  components: {ErrorMessages},
  data () {
    return {
      errors: {},
      localCompany: {}
    }
  },
  computed: {
    company () {
      return this.$store.getters['singleClient/company']
    },
    client () {
      return this.$store.getters['singleClient/item']
    }
  },
  methods: {
    save () {
      return new Promise((resolve, reject) => {
        this.$http.patch(`clients/${this.client.id}/company`, this.localCompany)
          .then(() => {
            this.errors = {}
            resolve()
          })
          .catch((error) => {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors
            }
            reject(error)
          })
      })
    },
    copyCompanyToLocalBuffer (value) {
      this.localCompany = _.cloneDeep(value)
    }
  },
  watch: {
    company: {
      deep: true,
      handler (value) {
        this.copyCompanyToLocalBuffer(value)
      }
    }
  },
  created () {
    this.copyCompanyToLocalBuffer(this.company)
  }
}
</script>
