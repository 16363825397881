<template>
  <section class="data-list-container">
    <vx-card>
      <v-data-table
        :hide-default-footer="true"
        :headers="headers"
        :items="paginatedItems.data"
        @update:sort-by="updateSortField"
        @update:sort-desc="updateSortType">

        <template v-slot:header="{props}">
          <tr>
            <th v-for="header in props.headers" :key="header.text">

              <template v-if="header.value === 'message'">
                <filter-input :filter-name="header.value" placeholder="Filtreaza" @filter="updateFilter"/>
              </template>

              <template v-if="header.value === 'agent_id'">
                <agent-filter :filter-name="header.value" placeholder="Filtreaza" @filter="updateFilter"/>
              </template>

              <template v-if="header.value === 'contacted_at'">
                <date-filter :filter-name="header.value" @filter="updateFilter"/>
              </template>

            </th>
          </tr>
        </template>

        <template v-slot:item.contacted_at="{item}">
          <span>{{item | moment("D-MM-YYYY HH:mm")}}</span>
        </template>
        <template v-slot:footer>
          <v-pagination
            :length="paginatedItems.last_page"
            :total-visible="5"
            :value="currentPage"
            color="#76bd43"
            @input="changePage"
          ></v-pagination>
        </template>
      </v-data-table>
    </vx-card>
  </section>
</template>

<script>
import DateFilter from '../../Activity/components/Filters/DateFilter'
import FilterInput from '@/components/general-form/FilterInput'
import AgentFilter from '@/views/Activity/components/Filters/AgentFilter'


export default {
  name: 'ContactHistoryList.vue',
  components: {
    DateFilter,
    FilterInput,
    AgentFilter
  },
  props: {
    customerId: {
      default: false
    }
  },
  data () {
    return {
      headers: [
        {text: 'Mesaj', value: 'message'},
        {text: 'Agent', value: 'agent_id'},
        {text: 'Data', value: 'contacted_at'}
      ],
      sortFiled: [],
      sortIsDesc: []
    }
  },
  computed: {
    paginatedItems () {
      return this.$store.getters['contactMessages/paginatedData']
    },
    currentPage () {
      return this.$store.getters['contactMessages/currentPage']
    },
    sortParam () {
      if (this.sortFiled.length) {
        const sortString = []
        for (let i = 0; i < this.sortFiled.length; i++) {
          sortString.push((this.sortIsDesc[i] === true ? '-' : '') + this.sortFiled[i])
        }
        return sortString.join(',')
      }
      return ''
    }
  },
  methods: {
    changePage (newPage) {
      this.$store.dispatch('contactMessages/changePage', newPage)
    },
    updateSortField (field) {
      this.sortFiled = field
    },
    updateSortType (isDesc) {
      this.sortIsDesc = isDesc
    },
    updateFilter (searchValue, filterName) {
      if (searchValue === '') {
        this.$store.dispatch('contactMessages/deleteFilter', filterName)
      } else {
        this.$store.dispatch('contactMessages/addFilter', {filterName, searchValue})
      }
    },
    setCustomerId () {
      this.$store.dispatch('contactMessages/setCurrentCustomerId', this.customerId)
    },
    loadItems () {
      this.$store.dispatch('contactMessages/loadItems', this.customerId)
    }
  },
  watch: {
    customerId (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.setCustomerId()
      }
    },
    sortParam (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$store.dispatch('contactMessages/changeSort', newValue)
      }
    }
  },
  created () {
    this.setCustomerId()
    this.loadItems()
  }
}
</script>

<style scoped>

</style>
