<template>
  <v-card>
    <v-simple-table>
      <template v-slot:default>
        <thead>
        <tr>
          <th style="width: 200px">
            Categorie
          </th>
          <th>
            Cautare
          </th>
          <th style="width: 200px">
            Data
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in logs.data" :key="item.id">
          <td>
            <div v-if="item.category">
              {{ item.category.name }}
            </div>
          </td>
          <td>{{ item.search_term }}</td>
          <td>{{ item.created_at | moment('D-MM-YYYY H:mm:ss') }}</td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
export default {
  name: 'SearchLogDisplay',
  data () {
    return {
      logs: {
        data: []
      }
    }
  },
  props: {
    customerId: {
      default: false
    }
  },
  methods: {
    loadSearchLogs (page) {
      let customerFilter = ''
      if (this.customerId) {
        customerFilter = `filter[customer_id]=${this.customerId}`
      }

      this.$http.get(`analytics/search?page=${page}&${customerFilter}&include=category`)
        .then(({data}) => {
          this.logs = data
        })
    }
  },
  watch: {
    customerId: {
      immediate: true,
      handler () {
        this.loadSearchLogs(1)
      }
    }
  }
}
</script>

