<template>
  <section class="data-list-container">
    <vx-card>
      <v-data-table
        :hide-default-footer="true"
        :headers="headers"
        :items="paginatedItems.data"
        @update:sort-by="updateSortField"
        @update:sort-desc="updateSortType">


        <template v-slot:item.values="{item}">
          <div v-if="item.type === 'customer_finished_order'">
            <customer-finished-order :values="item.values"></customer-finished-order>
          </div>
        </template>

        <template v-slot:item.contacted_at="{item}">
          <span>{{item | moment("D-MM-YYYY HH:mm")}}</span>
        </template>
        <template v-slot:footer>
          <v-pagination
            :length="paginatedItems.last_page"
            :total-visible="5"
            :value="currentPage"
            color="#76bd43"
            @input="changePage"
          ></v-pagination>
        </template>
      </v-data-table>
    </vx-card>
  </section>
</template>

<script>

import CustomerFinishedOrder from "./CustomerFinishedOrder";

export default {
  name: "ActivityHistory.vue",
  components: {
    CustomerFinishedOrder
  },
  props: {
    customerId: {
      default: false
    },
  },
  data () {
    return {
      headers: [
        {text: 'Label', value: 'message'},
        {text: 'Agent', value: 'agent_id'},
        {text: 'Data', value: 'created_at'},
      ],
      sortFiled: [],
      sortIsDesc: []
    }
  },
  computed: {
    paginatedItems () {
      return this.$store.getters['customerActivityHistory/paginatedData']
    },
    currentPage () {
      return this.$store.getters['customerActivityHistory/currentPage']
    },
    sortParam () {
      if (this.sortFiled.length) {
        const sortString = []
        for (let i = 0; i < this.sortFiled.length; i++) {
          sortString.push((this.sortIsDesc[i] === true ? '-' : '') + this.sortFiled[i])
        }
        return sortString.join(',')
      }
      return ''
    }
  },
  methods: {
    changePage (newPage){
      this.$store.dispatch('customerActivityHistory/changePage', newPage, this.customerId)
    },
    updateSortField (field) {
      this.sortFiled = field
    },
    updateSortType (isDesc) {
      this.sortIsDesc = isDesc
    },
    updateFilter (searchValue, filterName) {
      if (searchValue === '') {
        this.$store.dispatch('customerActivityHistory/deleteFilter', filterName)
      } else {
        this.$store.dispatch('customerActivityHistory/addFilter', {filterName, searchValue})
      }
    },
    setCustomerId(){
      this.$store.dispatch('customerActivityHistory/setCurrentCustomerId', this.customerId)
    },
    loadItems () {
      this.$store.dispatch('customerActivityHistory/loadItems')
    }
  },
  watch: {
    sortParam (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$store.dispatch('customerActivityHistory/changeSort', newValue)
      }
    }
  },
  created() {
    this.setCustomerId();
    this.loadItems()
  }
}
</script>

<style scoped>

</style>
