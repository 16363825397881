<template>
  <v-card class="p-2"  flat>
    <template slot="progress">
      <v-progress-linear
          absolute
          bottom
          color="#01a7e3"
          height="5"
          indeterminate
          rounded
      ></v-progress-linear>
    </template>
    <div class="mb-4">
      <v-text-field v-model="localClient.first_name" dense hide-details label="Nume" outlined/>
      <error-messages :errors="errors.first_name"/>
    </div>

    <div class="mb-4">
      <v-text-field v-model="localClient.last_name" dense hide-details label="Prenume" outlined/>
      <error-messages :errors="errors.last_name"/>
    </div>

    <div class="mb-4">
      <v-text-field v-model="localClient.phone" dense hide-details label="Nr. Telefon" outlined/>
      <error-messages :errors="errors.phone"/>
    </div>

    <div class="mb-4">
      <v-text-field v-model="localClient.email" dense hide-details label="Email" outlined/>
      <error-messages :errors="errors.email"/>
    </div>

    <div class="mb-4">
      <zone-selector v-model="localClient.zone_id" dense hide-details label="Zona" outlined/>
      <error-messages :errors="errors.zone_id"/>
    </div>

    <div class="mb-4">
      <county-selector v-model="localClient.county_id" :include-all="true" dense hide-details label="Judetul" outlined/>
      <error-messages :errors="errors.county_id"/>
    </div>

    <div class="mb-4">
      <city-selector v-model="localClient.city_id" :county="localClient.county_id" dense hide-details
                     label="Localitatea" outlined/>
      <error-messages :errors="errors.city_id"/>
    </div>

  </v-card>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'
import ZoneSelector from '@/components/autocompletes/ZoneSelector'
import CountySelector from '@/components/autocompletes/CountySelector'
import CitySelector from '@/components/autocompletes/CitySelector'
import ClientTypeSelector from '@/components/autocompletes/ClientTypeSelector'
import _ from 'lodash'

export default {
  name: 'CustomerPersonalInformation',
  components: {
    ClientTypeSelector,
    CitySelector,
    CountySelector,
    ZoneSelector,
    ErrorMessages
  },
  data () {
    return {
      errors: {},
      localClient: {}
    }
  },
  computed: {
    client () {
      return this.$store.getters['singleClient/item']
    }
  },
  methods: {
    save () {
      return new Promise((resolve, reject) => {
        this.$http.patch(`clients/${this.client.id}`, this.localClient)
            .then(() => {
              this.errors = {}
              resolve()
            })
            .catch((error) => {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors
              }
              reject(error)
            })
      })

    }
  },
  watch: {
    client: {
      deep: true,
      handler (value) {
        this.localClient = _.cloneDeep(value)
      }
    }
  }
}
</script>
