<template>
  <div class="px-4">
    <v-autocomplete
      v-model="selectOption"
      :items="agents"
      :label="placeholder"
      class="filter-select text-sm"
      clearable
      item-text="full_name"
      item-value="id"
      single-line
    ></v-autocomplete>
  </div>
</template>

<script>
export default {
  name: 'AgentTypeFilter',
  props: {
    filterName: {
      required: true
    },
    placeholder: {
      default: 'Filtreaza...'
    }
  },
  data () {
    return {
      // options: [],
      selectOption: ''
    }
  },
  computed: {
    agents () {
      return this.$store.getters['agents/data']
    }
  },
  watch: {
    selectOption () {
      if (typeof this.selectOption === 'undefined') {
        this.$emit('filter', '', this.filterName)
      } else {
        this.$emit('filter', this.selectOption, this.filterName)
      }
    }
  }
  // mounted() {
  //   this.$http.get('ClientsCompany/zone').then(({data}) => this.options = data.data)
  // }
}
</script>

<style scoped>
</style>
