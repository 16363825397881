<template>
  <v-card flat>
    <v-btn v-if="!reseller.activated" color="primary" class="primary-button" small @click="activateReseller">
      Confirma si trimite mail de confirmare
    </v-btn>

    <v-btn v-else-if="!reseller.email_confirmed" color="primary" class="primary-button" small @click="resendConfirmationEmail">
      Trimite mail de confirmare
    </v-btn>
  </v-card>
</template>

<script>
export default {
  name: 'ResellerConfirmationButtons',
  props: {
    reseller: {
      required: true
    }
  },
  computed: {
    activated () {
      if (this.reseller) {
        return this.reseller.activated
      }
      return ''
    }
  },
  methods: {
    resendConfirmationEmail () {
      this.$store.dispatch('startLoading')
      this.$http.patch(`clients/resellers/${this.reseller.id}/resend-activation`)
        .then(() => {
          this.$vs.notify({
            title: 'Succes!',
            text: 'Emailul de confirmare a fost trimis cu succes!',
            color: 'success'
          })
          this.$store.dispatch('singleClient/load', this.reseller.id)
          this.$store.dispatch('stopLoading')
        })
        .catch(() => {
          this.$vs.notify({
            title: 'Eroare!',
            text: 'A aparut o eroare la trimiterea emailului. Va rugam reincarcati pagina sau incercati mai tarziu',
            color: 'danger'
          })
          this.$store.dispatch('stopLoading')
        })
        .finally(() => {
          this.$store.dispatch('stopLoading')
        })
    },
    activateReseller () {
      this.$store.dispatch('startLoading')
      this.$http.patch(`clients/resellers/${this.reseller.id}/activate`)
        .then(() => {
          this.$store.dispatch('singleClient/load', this.reseller.id)
          this.$vs.notify({
            title: 'Succes!',
            text: 'Emailul de confirmare a fost trimis cu succes!',
            color: 'success'
          })
          this.$store.dispatch('stopLoading')
        })
        .catch(() => {
          this.$vs.notify({
            title: 'Eroare!',
            text: 'A aparut o eroare la trimiterea emailului. Va rugam reincarcati pagina sau incercati mai tarziu',
            color: 'danger'
          })
          this.$store.dispatch('stopLoading')
        })
    }
  }
}
</script>

<style scoped>

</style>
